import React, { useCallback, SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import { Tabs as BaseTabs, Tab } from '@mui/material';
import { useUserPlan } from 'dashboard/utils';

interface IProps {
  currentTab: number;
  onTabChange: (value: number) => void;
}

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

export const Tabs: React.FC<IProps> = ({ currentTab, onTabChange }) => {
  const { userPlan } = useUserPlan();

  const handleChange = useCallback(
    (_event: SyntheticEvent, value: number) => {
      onTabChange(value);
    },
    [onTabChange]
  );

  if (userPlan !== 'Demo' && userPlan !== 'Enterprise') {
    return null;
  }

  return (
    <Wrapper>
      <BaseTabs onChange={handleChange} value={currentTab}>
        <Tab label="CAA" />
        <Tab label="Detect" />
      </BaseTabs>
    </Wrapper>
  );
};
