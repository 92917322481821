import React, { useState } from 'react';
import { Header, Tabs, Infographics } from './components';

export const GeneralStatistics: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <Header />
      <Tabs currentTab={currentTab} onTabChange={setCurrentTab} />
      <Infographics currentTab={currentTab} onTabChange={setCurrentTab} />
    </>
  );
};
