import React from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'common/consts';

interface IProps {
  userPlan: TUserPlan;
}

const Wrapper = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: 60px;
`;

const MailLink = styled.a`
  color: ${COLORS.primary};
`;

export const SupportedMessage: React.FC<IProps> = ({ userPlan }) => {
  if (userPlan === 'None') {
    return (
      <Wrapper>
        If you want to access a demo or subscribe to one of the plans, please contact us <MailLink href='mailto:team@smartshopping.ai'>team@smartshopping.ai</MailLink>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      If you would like to renew your subscription please contact us <MailLink href='mailto:team@smartshopping.ai'>team@smartshopping.ai</MailLink>
    </Wrapper>
  );
}
