import React from 'react';
import styled from '@emotion/styled';
import { useStats } from 'dashboard/utils';
import { SearchMerchantInput, SelectDateRange } from './components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
`;

const SearchSection = styled.div`
  display: flex;
  align-items: center;
`;

export const Header: React.FC = () => {
  const { currentShop } = useStats();

  return (
    <Wrapper>
      <Title>
        {`Result for "${
          currentShop?.name || 'All Stores'
        }"`}
      </Title>
      <SearchSection>
        <SearchMerchantInput />
        <SelectDateRange />
      </SearchSection>
    </Wrapper>
  );
};
