import React from 'react';
import styled from '@emotion/styled';
import { ContentBox as BaseBox } from 'dashboard/components';
import { COLORS } from 'common/consts';

const ContentBox = styled(BaseBox)`
  padding: 20px 15px;
  margin: 0 15px 40px;
  height: 500px;
` 

const Description = styled.span`
  font-weight: 500;
  margin-left: 10px;
  color: ${COLORS.primary};
`;

const Divider = styled.hr`
  margin-top: 15px;
  color: ${COLORS.borderGray};
  background-color: ${COLORS.borderGray};
  border: none;
  height: 1px;
  margin: 15px -15px 0;
`;

interface IProps {
  description: string;
  fullWidth?: boolean;
};

export const Box: React.FC<IProps> = ({ description, fullWidth, children }) => (
  <ContentBox fullWidth={fullWidth}>
    <Description>{description}</Description>
    <Divider />
    {children}
  </ContentBox>
);
