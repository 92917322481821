import styled from '@emotion/styled';
import { COLORS } from 'common/consts';

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  font-size: 0.875rem;
  font-weight: 700;
  min-height: 50px;
  padding-right: 6px;
`;
