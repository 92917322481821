import React from 'react';
import { useUserPlan, checkPlanOnExpired } from 'dashboard/utils';
import { ShopsProvider } from 'dashboard/contexts/ShopsContext';
import { StatsProvider } from 'dashboard/contexts/StatsContext';
import { SupportedMessage } from './components/SupportedMessage';
import { MainInfo } from './components/MainInfo/MainInfo';

export const Statistics: React.FC = () => {
  const { userPlan } = useUserPlan();
  
  if (checkPlanOnExpired(userPlan)) {
    return <SupportedMessage userPlan={userPlan} />;
  }

  return (
    <ShopsProvider>
      <StatsProvider>
        <MainInfo />
      </StatsProvider>
    </ShopsProvider>
  );
}
