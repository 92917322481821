import { apiUrl, castError } from 'common/utils';

type Params = {
  clientID?: string;
  shopId?: string;
  startDate: number;
  page: number;
  perPage: number;
};

async function fetchDetectCodeStats(
  authToken: string,
  params: Params
): Promise<IDetectCodeStat[]> {
  const query = {
    clientID: params?.clientID || 'demo',
    shop: params?.shopId,
    startDate: params.startDate,
    page: params.page,
    perPage: params.perPage,
  };
  const res = await fetch(apiUrl('stats/detect', query), {
    headers: {
      Authorization: authToken,
    },
  });
  return castError(res);
}

export default fetchDetectCodeStats;
