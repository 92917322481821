const convertStatsToHumanFormat = (stats: IStats, dateRange: IDateRange) =>
  dateRange.dates
    .map((rangeDate) => {
      const [year, month, day] = rangeDate.split('-');
      return {
        [rangeDate]: {
          checkout: stats?.[year]?.[month]?.[day]?.checkout ?? 0,
          inspect: stats?.[year]?.[month]?.[day]?.show ?? 0,
          show_slider: stats?.[year]?.[month]?.[day]?.showSlider ?? 0,
          close_slider: stats?.[year]?.[month]?.[day]?.closeSlider ?? 0,
          start: stats?.[year]?.[month]?.[day]?.start ?? 0,
          success: stats?.[year]?.[month]?.[day]?.success ?? 0,
          abort: stats?.[year]?.[month]?.[day]?.abort ?? 0,
          avg_total: stats?.[year]?.[month]?.[day]?.avgTotal ?? 0,
          avg_total_on_discount:
            stats?.[year]?.[month]?.[day]?.avgDiscountTotal ?? 0,
          avg_discount: stats?.[year]?.[month]?.[day]?.avgDiscount ?? 0,
        },
      };
    })
    .reverse()
    .concat({
      total: {
        checkout: stats?.total?.checkout ?? 0,
        inspect: stats?.total?.show ?? 0,
        show_slider: stats?.total?.showSlider ?? 0,
        close_slider: stats?.total?.closeSlider ?? 0,
        start: stats?.total?.start ?? 0,
        success: stats?.total?.success ?? 0,
        abort: stats?.total?.abort ?? 0,
        avg_total: stats?.total?.avgTotal ?? 0,
        avg_total_on_discount: stats?.total?.avgDiscountTotal ?? 0,
        avg_discount: stats?.total?.avgDiscount ?? 0,
      },
    });

export default convertStatsToHumanFormat;
