import React from 'react';
import styled from '@emotion/styled';

interface IProps {
  description: string;
  stat: number | string;
  size?: 'small' | 'large';
}

interface IContentProps {
  size: 'small' | 'large';
}

const Description = styled.span<IContentProps>`
  font-size: ${(props) => (props.size === 'small' ? '0.875rem' : '1rem')};
  line-height: normal;
  margin-bottom: 10px;
`;

const Number = styled.div<IContentProps>`
  font-size: ${(props) => (props.size === 'small' ? '1.5rem' : '2rem')};
  font-weight: bold;
  margin-bottom: 10px;
`;

const numberWithCommas = (value: number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const StatCardData: React.FC<IProps> = ({ description, stat, size = 'large' }) => (
  <>
    <Description size={size}>{description}</Description>
    <Number size={size}>{typeof stat === 'number' ? numberWithCommas(stat) : stat}</Number>
  </>
);
