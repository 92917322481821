import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import TextField from '@mui/material/TextField';
import { Loupe } from 'dashboard/components/Icons';

interface IProps {
  onSearch: (query: string) => void;
}

const InputWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 22px;
`;

const LoupeWrapper = styled.div`
  margin-right: 10px;
`;

const textInputClass = css`
  font-size: 0.875rem;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
`;

const SearchInput: React.FC<IProps> = ({ onSearch }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <InputWrapper>
      <TextField
        placeholder="Search store"
        onChange={handleInputChange}
        InputProps={{
          classes: { input: textInputClass },
          startAdornment: (
            <LoupeWrapper>
              <Loupe />
            </LoupeWrapper>
          ),
        }}
      />
    </InputWrapper>
  );
};

export default SearchInput;
