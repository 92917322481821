import dayjs from 'dayjs';
import times from 'lodash/times';
import { DB_DATE_FORMAT } from 'dashboard/consts';

const today = dayjs();

const getDates = (type: TDateRange): IDateRange => {
  let dates: string[] = [];
  switch (type) {
    case 'lastWeek':
      dates = times(7, (index) =>
        today.subtract(index + 1, 'day').format(DB_DATE_FORMAT)
      ).reverse();
      break;
    case 'last30Days':
      console.log()
      dates = times(30, (index) =>
        today.subtract(index + 1, 'day').format(DB_DATE_FORMAT)
      ).reverse();
      break;
    case 'last90Days':
      dates = times(90, (index) =>
        today.subtract(index + 1, 'day').format(DB_DATE_FORMAT)
      ).reverse();
      break;
    default:
      dates = [];
      break;
  }

  return {
    type,
    dates,
    start: dates[0],
    end: dayjs(dates[dates.length - 1])
      .add(1, 'day')
      .format(DB_DATE_FORMAT),
  };
};

export default getDates;
