import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { useStats, useShops } from 'dashboard/utils';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const mainClass = css`
  width: 300px;
  min-height: 44px;
`;

const endAdronmentClass = css`
  top: initial;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SearchMerchantInput: React.FC = () => {
  const { shops, loadingShops } = useShops();
  const { onChoiceStore, onResetStore } = useStats();

  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    merchant: IMerchant | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      onResetStore();
    }
    if (!merchant) {
      return;
    }
    if (reason === 'selectOption') {
      onChoiceStore(merchant);
    }
  };

  return (
    <Autocomplete
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      options={shops || []}
      loading={loadingShops}
      clearOnBlur={false}
      classes={{ endAdornment: endAdronmentClass }}
      loadingText={
        <LoaderWrapper>
          <CircularProgress color="secondary" />
        </LoaderWrapper>
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          className={mainClass}
          variant="outlined"
          placeholder="All stores"
          {...params}
        />
      )}
      clearIcon={<ClearIcon />}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
    />
  );
};
