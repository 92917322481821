import React from 'react';
import { css } from '@emotion/css';
import { CSVLink } from 'react-csv';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useStats, convertStatsToHumanFormat } from 'dashboard/utils';
import { COLORS } from 'common/consts';

const textInputClass = css`
  padding-top: 4.5px;
  padding-bottom: 4.5px;
`;

const csvLinkClass = css`
  text-decoration: none;
  color: ${COLORS.black};
`;

export const ExportButtons: React.FC = () => {
  const { stats, dateRange } = useStats();

  const exportJson = () => {
    if (!stats) {
      return;
    }
    const name = 'stats.json';
    const a = document.createElement('a');
    const type = name.split('.').pop();
    a.href = URL.createObjectURL(
      new Blob(
        [
          JSON.stringify(
            convertStatsToHumanFormat(stats, dateRange),
            undefined,
            2
          ),
        ],
        {
          type: `text/${type === 'txt' ? 'plain' : type}`,
        }
      )
    );
    a.download = name;
    a.click();
  };

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 84,
        marginTop: '16px',
        marginBottom: '16px',
        marginLeft: '10px',
      }}
      size="small"
    >
      <TextField
        value="export"
        select
        SelectProps={{
          IconComponent: KeyboardArrowDownRoundedIcon,
        }}
        InputProps={{
          classes: { input: textInputClass },
        }}
      >
        <MenuItem value="export" sx={{ display: 'none' }}>
          Export
        </MenuItem>
        <MenuItem onClick={exportJson} value="json">
          JSON
        </MenuItem>
        <CSVLink
          className={csvLinkClass}
          filename="stats.csv"
          data={JSON.stringify(
            convertStatsToHumanFormat(stats!, dateRange),
            undefined,
            2
          )}
        >
          <MenuItem value="csv">CSV</MenuItem>
        </CSVLink>
      </TextField>
    </FormControl>
  );
};
