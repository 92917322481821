import React, { createContext, useContext } from 'react';
import { UserContext } from 'common/contexts/UserContext';

export interface IUserPlanContext {
  userPlan: TUserPlan;
}

const UserPlanContext = createContext({} as IUserPlanContext);

const getUserPlan = (planFromServer: string): TUserPlan => {
  switch (planFromServer) {
    case 'none':
      return 'None';
    case 'expired':
      return 'Expired';
    case 'checkout':
      return 'Standard';
    case 'inspect':
      return 'Standard+';
    case 'detect':
      return 'Essential';
    case 'caa':
      return 'Plus';
    case 'full':
      return 'Enterprise';
    default:
      return 'Demo';
  }
};

const UserPlanProvider: React.FC = ({ children }) => {
  const { user } = useContext(UserContext);
  const userPlan = getUserPlan(user.attributes?.['custom:ss_plan'] ?? '');
  const value: IUserPlanContext = {
    userPlan,
  };

  return (
    <UserPlanContext.Provider value={value}>
      {children}
    </UserPlanContext.Provider>
  );
};

export { UserPlanProvider };

export default UserPlanContext;
