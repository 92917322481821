import dayjs from 'dayjs';
import { CHART_DATE_FORMAT } from 'dashboard/consts';

interface IWeekData {
  date: string;
  stats: IStatsUnit;
}

type TDataVariant =
  | 'abort'
  | 'success'
  | 'start'
  | 'detect'
  | 'closeSlider'
  | 'showSlider'
  | 'show'
  | 'checkout';

const getCoordsFromData = (weekData: IWeekData[], variant: TDataVariant) =>
  weekData.map((dayData) => ({
    x: dayjs(dayData.date).format(CHART_DATE_FORMAT),
    y: dayData.stats[variant],
  }));

export default getCoordsFromData;
