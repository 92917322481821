import React from 'react';
import { Wrapper } from './components';
import { Authenticator } from '@aws-amplify/ui-react';
import { UserContext } from '../common/contexts/UserContext';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Dashboard } from 'dashboard/Dashboard';

const App = () => (
  <Authenticator>
    {({ signOut, user }) => (
      <UserContext.Provider value={{ user, signOut }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    )}
  </Authenticator>
);

export default () => (
  <Wrapper>
    <App />
  </Wrapper>
);
