import React from 'react';

export const Arrow: React.FC = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5172 0.38113C13.8479 0.786505 13.8243 1.38375 13.4463 1.76215L7.60396 7.60414C7.40812 7.79998 7.14613 7.90599 6.87436 7.90599C6.6025 7.90599 6.34099 7.80016 6.14441 7.60414L0.302302 1.76203C-0.100767 1.35848 -0.100767 0.705272 0.302302 0.30278C0.705508 -0.100907 1.35889 -0.100907 1.76188 0.302661L6.87436 5.41473L11.9867 0.30278C12.3647 -0.0756553 12.9626 -0.0993273 13.368 0.231783L13.4464 0.302736L13.5172 0.38113Z"
      fill="#343A40"
    />
  </svg>
);
