import { StandardTiles } from './StandartTiles';
import { CAATiles } from './CAATiles';
import { DetectTiles } from './DetectTiles';
import { StandardTable } from './StandardTable';
import { CAATable } from './CAATable';
import { DetectTable } from './DetectTable';

export {
  StandardTiles,
  CAATiles,
  DetectTiles,
  StandardTable,
  CAATable,
  DetectTable,
};
