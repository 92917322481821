import React, { CSSProperties } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RowSection from '../../../RowSection';
import {
  Loader,
  RowWrapper as BaseRowWrapper,
} from 'dashboard/components/ReactWindowList';
import { COLORS } from 'common/consts';
import { LAST_CHECK_DATE_FORMAT } from 'dashboard/consts';

interface IProps {
  data: IMerchant[];
  index: number;
  style: CSSProperties;
}

interface IRowProps {
  index: number;
}

const RowWrapper = styled(BaseRowWrapper)<IRowProps>`
  background-color: ${(props) => {
    if (props.index % 2 === 0) {
      return COLORS.lightGray;
    }
    return COLORS.white;
  }};
`;

const Row: React.FC<IProps> = ({ data, index, style }) => {
  if (!data[index]) {
    return <Loader style={style} />;
  }

  const lastCheckDate = data[index].lastCheck
    ? dayjs(parseInt(data[index].lastCheck, 10)).format(LAST_CHECK_DATE_FORMAT)
    : '-';

  return (
    <RowWrapper style={style} index={index}>
      <RowSection>{data[index].name}</RowSection>
      <RowSection>
        {data[index].available ? <CheckIcon /> : <CloseIcon />}
      </RowSection>
      <RowSection>
        {data[index].detect ? <CheckIcon /> : <CloseIcon />}
      </RowSection>
      <RowSection>
        {data[index].apply ? <CheckIcon /> : <CloseIcon />}
      </RowSection>
      <RowSection>{lastCheckDate}</RowSection>
    </RowWrapper>
  );
};

export default Row;
