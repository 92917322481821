import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useStats, getTitleFromDateRange } from 'dashboard/utils';
import { COLORS } from 'common/consts';
import { Calendar } from 'dashboard/components/Icons';

const CalendarWrapper = styled.div`
  margin: 5px 20px 0 4px;
`;

const textInputClass = css`
  padding-top: 10.5px;
  padding-bottom: 10.5px;
`;

export const SelectDateRange: React.FC = () => {
  const { changeDateRange } = useStats();
  const [dateRange, setDateRange] = useState<TDateRange>('lastWeek');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(event.target.value as TDateRange);
    changeDateRange(event.target.value as TDateRange);
  };
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 180,
        minHeight: 44,
        margin: 0,
        marginLeft: '25px',
        backgroundColor: COLORS.white,
      }}
      size="small"
    >
      <TextField
        select
        value={dateRange}
        onChange={handleChange}
        SelectProps={{
          IconComponent: KeyboardArrowDownRoundedIcon,
        }}
        InputProps={{
          classes: { input: textInputClass },
          startAdornment: (
            <CalendarWrapper>
              <Calendar />
            </CalendarWrapper>
          ),
        }}
      >
        <MenuItem value="lastWeek">
          {getTitleFromDateRange('lastWeek')}
        </MenuItem>
        <MenuItem value="last30Days">
          {getTitleFromDateRange('last30Days')}
        </MenuItem>
        <MenuItem value="last90Days">
          {getTitleFromDateRange('last90Days')}
        </MenuItem>
      </TextField>
    </FormControl>
  );
};
