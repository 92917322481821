import { apiUrl, castError } from 'common/utils';

type Params = {
  clientID?: string;
  startDate?: any;
  endDate?: any;
};

function fetchBestShopsStats(
  authToken: string,
  params?: Params
): Promise<IBestStats[]> {
  const query = {
    clientID: params?.clientID || 'demo',
    startDate: params?.startDate,
    endDate: params?.endDate,
  };
  return fetch(apiUrl('stats/best', query), {
    headers: {
      Authorization: authToken,
    },
  }).then(castError);
}

export default fetchBestShopsStats;
