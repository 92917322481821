import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { baseCardClass } from './StatCardWrapper';

export const LoadingCard: React.FC = () => (
  <Skeleton
    variant="rectangular"
    animation="wave"
    className={baseCardClass}
  />
);
