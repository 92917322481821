import React, { useState, createContext, useMemo, useEffect } from 'react';
import { fetchMerchants } from 'dashboard/utils';

export interface IShopsContext {
  loadingShops: boolean;
  shops: IMerchant[] | null;
}

const ShopsContext = createContext({} as IShopsContext);

const ShopsProvider: React.FC = ({ children }) => {
  const [shops, setShops] = useState<IMerchant[] | null>(null);
  const [loadingShops, setLoadingShops] = useState(true);

  const convertShopName = (rawShops: IMerchant[]) => {
    setShops(rawShops.map((shop) => ({ ...shop, name: shop.platform ? `${shop.name} (Platform)` : shop.name})))
  }

  useEffect(() => {
    fetchMerchants()
      .then(convertShopName)
      .catch((error) => console.log(error))
      .finally(() => setLoadingShops(false));
  }, []);

  const value: IShopsContext = useMemo(
    () => ({
      loadingShops,
      shops,
    }),
    [loadingShops, shops]
  );

  return (
    <ShopsContext.Provider value={value}>{children}</ShopsContext.Provider>
  );
};

export { ShopsProvider };

export default ShopsContext;
