import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { Serie } from '@nivo/line';
import {
  fetchBestShopsStats,
  useShops,
  useStats,
  getFullShopName,
} from 'dashboard/utils';
import { MAX_LENGTH_SHOP_NAME, CHART_DATE_FORMAT } from 'dashboard/consts';

interface IUseBestStats {
  bestStatsChartData: Serie[] | null;
  loadingBestStats: boolean;
}

const useBestStats = (): IUseBestStats => {
  const { dateRange } = useStats();
  const { shops, loadingShops } = useShops();
  const [bestStats, setBestStats] = useState<Serie[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!shops || loadingShops) {
      return;
    }

    setLoading(true);
    Auth.currentSession()
      .then((session) =>
        fetchBestShopsStats(session.getIdToken().getJwtToken(), {
          startDate: dateRange.start,
          endDate: dateRange.end,
        })
      )
      .then((res) => {
        let uniqueShops: string[] = [];
        res.forEach((row) => {
          if (!uniqueShops.includes(row.shop)) {
            uniqueShops = uniqueShops.concat(row.shop);
          }
        });

        const chartData = uniqueShops.map((uniqueShop) => {
          const checkouts = dateRange.dates.map((day) => {
            const findRow = res.find(
              (resRow) => resRow.shop === uniqueShop && resRow.date === day
            )?.checkouts;
            return {
              x: dayjs(day).format(CHART_DATE_FORMAT),
              y: findRow ? parseInt(findRow, 10) : 0,
            };
          });

          const fullShopName = getFullShopName(uniqueShop, shops);
          return {
            id:
              fullShopName.length > MAX_LENGTH_SHOP_NAME
                ? `${fullShopName.slice(0, MAX_LENGTH_SHOP_NAME - 1)}...`
                : fullShopName,
            data: checkouts,
          };
        });
        setBestStats(chartData.reverse());
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [shops, loadingShops, dateRange]);

  return {
    bestStatsChartData: bestStats,
    loadingBestStats: loading,
  };
};

export default useBestStats;
