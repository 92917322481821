const getTitleFromDateRange = (type: TDateRange) => {
  switch (type) {
    case 'lastWeek':
      return 'Last 7 days';
    case 'last30Days':
      return 'Last 30 days';
    case 'last90Days':
      return 'Last 90 days';
  }
};

export default getTitleFromDateRange;
