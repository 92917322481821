import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useShops } from 'dashboard/utils';
import { Box, EmptyBox } from 'dashboard/components/Box';
import { ListHeader } from 'dashboard/components/ReactWindowList';
import { SearchInput, RowSection, List } from './components';

const Container = styled.div`
  margin: 0 10px;
`;

const SupportedMerchantsList: React.FC = () => {
  const { shops, loadingShops } = useShops();
  const [showShops, setShowShops] = useState<IMerchant[]>([]);

  useEffect(() => {
    if (!loadingShops && shops) {
      setShowShops(shops);
    }
  }, [loadingShops]);

  const onSearch = (query: string) => {
    if (loadingShops || !shops) {
      return;
    }

    if (!query.length) {
      setShowShops(shops);
      return;
    }

    setShowShops(
      shops.filter((shop) =>
        shop.name.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  if (loadingShops || !shops) {
    return <EmptyBox />;
  }

  return (
    <Box description="Supported stores">
      <Container>
        <SearchInput onSearch={onSearch} />
        <ListHeader>
          <RowSection>Name</RowSection>
          <RowSection>Standard</RowSection>
          <RowSection>Essential</RowSection>
          <RowSection>Plus</RowSection>
          <RowSection>Last check</RowSection>
        </ListHeader>
        <List supportedShops={showShops} />
      </Container>
    </Box>
  );
};

export default SupportedMerchantsList;
