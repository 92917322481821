import React from 'react';
import { FixedSizeList } from 'react-window';
import { scrollStyle } from 'dashboard/components/ReactWindowList';
import Row from './components/Row';
import EmptyList from './components/EmptyList';

interface IProps {
  supportedShops: IMerchant[];
}

const List: React.FC<IProps> = ({ supportedShops }) => {
  const itemCount = supportedShops.length;

  if (!supportedShops.length) {
    return <EmptyList />;
  }

  return (
    <FixedSizeList
      className={scrollStyle}
      height={300}
      width="100%"
      itemSize={50}
      itemCount={itemCount}
      itemData={supportedShops}
    >
      {Row}
    </FixedSizeList>
  );
};

export default List;
