import { createContext } from 'react';
import { CognitoUserAmplify } from '@aws-amplify/ui-react/node_modules/@aws-amplify/ui';

export interface IUserContext {
  user: CognitoUserAmplify;
  signOut: (data?: Record<string | number | symbol, any> | undefined) => void;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export { UserContext };
