import React from 'react';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  isUpdated: boolean;
  onClick: () => void;
}

const rootButtonClass = css`
  position: absolute;
  top: 18px;
  right: 10px;
  font-size: 1rem;
  font-weight: 500;
`;

const rootProgressClass = css`
  position: absolute;
  top: 27px;
  right: 33px;
`;

export const ChangeInfoButton: React.FC<IProps> = ({
  isUpdated,
  onClick,
  children,
}) => {
  if (isUpdated) {
    return <CircularProgress classes={{ root: rootProgressClass }} size={20} />;
  }

  return (
    <Button
      classes={{ root: rootButtonClass }}
      variant="text"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
