import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { COLORS } from 'common/consts';

interface IProps {
  style: CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const Loader: React.FC<IProps> = ({ style }) => (
  <Wrapper style={style}>
    <CircularProgress sx={{ color: COLORS.primary }} />
  </Wrapper>
);
