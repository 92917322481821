import React from 'react';
import dayjs from 'dayjs';
import { useStats } from 'dashboard/utils';
import { CHART_DATE_FORMAT } from 'dashboard/consts';
import { Box, EmptyBox } from 'dashboard/components/Box';
import { ExportButtons } from './ExportButtons';
import {
  TableWrapper,
  TableCaption,
  TableCaptionCell,
  TableRow,
  TableCell,
} from './Table';

interface IProps {
  description: string;
  restCaptionsPart: JSX.Element;
  restRowPart: (tableRow: ITableRow) => JSX.Element;
}

export const BaseTable: React.FC<IProps> = ({
  description,
  restCaptionsPart,
  restRowPart,
}) => {
  const { tableRows, loadingStats } = useStats();

  if (loadingStats || !tableRows) {
    return <EmptyBox />;
  }

  return (
    <Box description={description}>
      <ExportButtons />
      <TableWrapper>
        <TableCaption>
          <TableCaptionCell>Date</TableCaptionCell>
          {restCaptionsPart}
        </TableCaption>
        {tableRows.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{dayjs(row.date).format(CHART_DATE_FORMAT)}</TableCell>
            {restRowPart(row)}
          </TableRow>
        ))}
      </TableWrapper>
    </Box>
  );
};
