import React from 'react';

export const Loupe: React.FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.41669 11.6667C9.31618 11.6667 11.6667 9.31618 11.6667 6.41669C11.6667 3.51719 9.31618 1.16669 6.41669 1.16669C3.51719 1.16669 1.16669 3.51719 1.16669 6.41669C1.16669 9.31618 3.51719 11.6667 6.41669 11.6667Z"
      stroke="#7D828C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8333 12.8333L10.5 10.5"
      stroke="#7D828C"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
