export const API_URL = 'https://stat.smartshopping.ai';

export const COLORS = {
  primary: '#6B5DEF',
  black: '#000',
  lightBlack: '#2A2D33',
  white: '#FFF',
  green: '#8DDCB3',
  lightGreen: '#5FCF9480',
  red: '#EF9292',
  yellow: '#FFDA84',
  orange: '#F3AD70',
  blue: '#0000FF',
  gray: '#7D828C',
  lightGray: '#EEE',
  extraLightGray: '#F7F7F7',
  borderGray: '#EFF0F2',
};
