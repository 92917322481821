import { createTheme } from '@mui/material/styles';
import { COLORS } from 'common/consts';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.black,
    },
  },
  typography: {
    fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'none',
          fontWeight: 'initial',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {
          textTransform: 'none',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          fontSize: '0.875rem',
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          color: COLORS.gray,
          backgroundColor: COLORS.white,
        },
        paper: {
          borderRadius: 6,
          backgroundColor: COLORS.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: `1px solid ${COLORS.borderGray}`,
          borderRadius: 6,
        },
        input: {
          fontSize: '0.875rem',
          color: COLORS.gray,
          backgroundColor: COLORS.white,
          '::placeholder': {
            opacity: 1,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
  },
});

export default theme;
