import React from 'react';
import { BaseTable } from './BaseTable';
import { TableCaptionCell, TableCell } from './Table';

const captions = ['Inspects', 'Avg total', 'Detects', 'Shows', 'Closes'];

export const DetectTable: React.FC = () => (
  <BaseTable
    description="Detect statistics"
    restCaptionsPart={
      <>
        {captions.map((caption) => (
          <TableCaptionCell>{caption}</TableCaptionCell>
        ))}
      </>
    }
    restRowPart={(tableRow) => (
      <>
        <TableCell>{tableRow.stats.show}</TableCell>
        <TableCell>{tableRow.stats.avgTotal}</TableCell>
        <TableCell>{tableRow.stats.detect}</TableCell>
        <TableCell>{tableRow.stats.showSlider}</TableCell>
        <TableCell>{tableRow.stats.closeSlider}</TableCell>
      </>
    )}
  />
);
