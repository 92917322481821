import React from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'common/consts';

interface IProps {
  className?: string;
  fullWidth?: boolean;
}

const Wrapper = styled.div<IProps>`
  width: ${(props) => props.fullWidth ? '100%' : 'calc(50% - 30px)'};
  max-height: 500px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.borderGray};
  border-radius: 6px;
`;

export const ContentBox: React.FC<IProps> = ({ className, fullWidth = false, children }) => (
  <Wrapper className={className} fullWidth={fullWidth}>{children}</Wrapper>
);
