import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { COLORS } from 'common/consts';

interface IProps {
  contentColor?: string;
  backgroundColor?: string;
}

export const baseCardClass = css`
  width: calc(20% - 30px);
  height: 200px;
  border-radius: 5px;
  border: 1px solid ${COLORS.borderGray};
  margin: 0 15px 30px;
`;

const Wrapper = styled.div<IProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${(props) => props.contentColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 20px;
`;

export const StatCardWrapper: React.FC<IProps> = ({
  contentColor = 'black',
  backgroundColor = COLORS.primary,
  children,
}) => (
  <Wrapper
    className={baseCardClass}
    contentColor={contentColor === 'black' ? COLORS.black : COLORS.white}
    backgroundColor={backgroundColor}
  >
    {children}
  </Wrapper>
);
