import React from 'react';
import styled from '@emotion/styled';
import { useUserPlan, useBestStats } from 'dashboard/utils';
import { GeneralStatistics, Chart, DetectCodeStatistics } from './components';
import { SupportedMerchantsList } from 'dashboard/widgets';

const OtherStatistics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -15px 0;
  padding-bottom: 90px;
`;

export const MainInfo: React.FC = () => {
  const { userPlan } = useUserPlan();
  const { bestStatsChartData, loadingBestStats } = useBestStats();

  return (
    <>
      <GeneralStatistics />
      <OtherStatistics>
        {userPlan === 'Essential' && <DetectCodeStatistics />}
        <Chart
          title={'Checkout events for top 5 stores'}
          chartData={bestStatsChartData}
          loading={loadingBestStats}
        />
        <SupportedMerchantsList />
      </OtherStatistics>
    </>
  );
};
