import React from 'react';
import { getPercent, convertToNumber } from 'dashboard/utils';
import { BaseTable } from './BaseTable';
import { TableCaptionCell, TableCell } from './Table';

const captions = [
  'Inspects',
  'Start rate',
  'Success rate',
  'Avg total',
  'Avg discount',
];

export const CAATable: React.FC = () => (
  <BaseTable
    description="CAA statistics"
    restCaptionsPart={
      <>
        {captions.map((caption) => (
          <TableCaptionCell>{caption}</TableCaptionCell>
        ))}
      </>
    }
    restRowPart={(tableRow) => (
      <>
        <TableCell>{tableRow.stats.show}</TableCell>
        <TableCell>
          {getPercent(
            convertToNumber(tableRow.stats.start),
            convertToNumber(tableRow.stats.showSlider)
          )}
        </TableCell>
        <TableCell>
          {getPercent(
            convertToNumber(tableRow.stats.success),
            convertToNumber(tableRow.stats.start)
          )}
        </TableCell>
        <TableCell>{tableRow.stats.avgDiscountTotal}</TableCell>
        <TableCell>{tableRow.stats.avgDiscount}</TableCell>
      </>
    )}
  />
);
