import styled from '@emotion/styled';
import { RowSection as BaseRowSection } from 'dashboard/components/ReactWindowList'

const RowSection = styled(BaseRowSection)`
  &:first-of-type {
    flex-grow: 2;
  }
`;

export default RowSection;
