import { css } from '@emotion/css';

export const scrollStyle = css`
  scrollbar-color: #bfccd6 #ebf1f5;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ebf1f5;
  }
  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ebf1f5;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bfccd6;
    border: 1px solid #bfccd6;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfccd6;
    border: 1px solid #bfccd6;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #bfccd6;
    border: 1px solid #bfccd6;
  }
`;
