import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import styled from '@emotion/styled';
import { checkPlanOnExpired, useUserPlan } from 'dashboard/utils';
import { ContentBox as BaseBox } from 'dashboard/components';
import { COLORS } from 'common/consts';
import { UserContext } from 'common/contexts/UserContext';
import { ChangeInfoButton, Plan } from './components';

const ContentBox = styled(BaseBox)`
  position: relative;
  padding: 50px 90px 30px 40px;
  margin-bottom: 45px;
`;

const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  min-height: 385px;
`;

const Row = styled.div`
  display: table-row;
`;

const Title = styled.div`
  display: table-cell;
  white-space: nowrap;
  color: ${COLORS.gray};
  padding: 10px 10px 10px 0;
`;

const InfoWrapper = styled.div`
  display: table-cell;
  text-align: right;
`;

const Info = styled.input<{ disabled?: boolean }>`
  width: 100%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${COLORS.lightBlack};
  background-color: ${(props) =>
    props.disabled === false ? 'rgba(0,0,0,0.1)' : 'transparent'};
  border: none;
  outline: none;
  padding: 10px 10px;
  border-radius: 6px;

  &:disabled {
    -webkit-text-fill-color: ${COLORS.lightBlack};
  }
`;

export const UserInfo: React.FC = () => {
  const { user } = useContext(UserContext);
  const { userPlan } = useUserPlan();
  const [isUpdated, setIsUpdated] = useState(false);
  const [name, setName] = useState<string>(user.attributes?.name ?? '');
  const [companyName, setCompanyName] = useState<string>(
    user.attributes?.['custom:company_name'] ?? ''
  );
  const [companyAddress, setCompanyAddress] = useState<string>(
    user.attributes?.['custom:company_address'] ?? ''
  );
  const [isEditable, setIsEditable] = useState(false);

  const onChangeButtonClick = async () => {
    if (isEditable) {
      setIsUpdated(true);
      await Auth.updateUserAttributes(user, {
        name,
        'custom:company_name': companyName,
        'custom:company_address': companyAddress,
      });
      setIsUpdated(false);
    }
    setIsEditable((prev) => !prev);
  };

  return (
    <ContentBox>
      <ChangeInfoButton isUpdated={isUpdated} onClick={onChangeButtonClick}>
        {isEditable ? 'Save' : 'Edit'}
      </ChangeInfoButton>
      <Table>
        <Row>
          <Title>Name</Title>
          <InfoWrapper>
            <Info
              value={name}
              onChange={(event) => setName(event.target.value)}
              disabled={!isEditable || isUpdated}
            />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>Company name</Title>
          <InfoWrapper>
            <Info
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              disabled={!isEditable || isUpdated}
            />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>Company address</Title>
          <InfoWrapper>
            <Info
              value={companyAddress}
              onChange={(event) => setCompanyAddress(event.target.value)}
              disabled={!isEditable || isUpdated}
            />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>Email</Title>
          <InfoWrapper>
            <Info value={user.attributes?.email} readOnly />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>Client ID</Title>
          <InfoWrapper>
            <Info value={user.attributes?.['custom:clientID'] ?? ''} readOnly />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>API key</Title>
          <InfoWrapper>
            <Info value={user.attributes?.['custom:key'] ?? ''} readOnly />
          </InfoWrapper>
        </Row>
        <Row>
          <Title>Plan</Title>
          <Plan isExpired={checkPlanOnExpired(userPlan)}>
            {userPlan}
          </Plan>
        </Row>
      </Table>
    </ContentBox>
  );
};
