import React, { CSSProperties } from 'react';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { getFullShopName, useShops } from 'dashboard/utils';
import { DETECT_CODE_DATE_FORMAT } from 'dashboard/consts';
import {
  Loader,
  RowWrapper,
  RowSection,
} from 'dashboard/components/ReactWindowList';

interface IProps {
  data: IDetectCodeStat[];
  index: number;
  style: CSSProperties;
}

export const Row: React.FC<IProps> = ({ data, index, style }) => {
  const { shops } = useShops();

  if (!data[index]) {
    return <Loader style={style} />;
  }

  return (
    <RowWrapper style={style}>
      <RowSection>
        {dayjs.unix(data[index].date).format(DETECT_CODE_DATE_FORMAT)}
      </RowSection>
      <RowSection>{getFullShopName(data[index].shop, shops!)}</RowSection>
      <RowSection>{data[index].code}</RowSection>
      <RowSection>
        {data[index].valid ? <CheckIcon /> : <CloseIcon />}
      </RowSection>
    </RowWrapper>
  );
};
