import styled from '@emotion/styled';

export const RowSection = styled.div`
  flex: 1;
  &:first-of-type {
    padding-left: 20px;
  }
  &:not(:first-of-type) {
    text-align: center;
  }
`;
