import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { useDetectCodeStats, useShops } from 'dashboard/utils';
import {
  ListHeader,
  RowSection,
  scrollStyle,
} from 'dashboard/components/ReactWindowList';
import { EmptyBox, Box } from 'dashboard/components/Box';
import { Row } from './components/Row';

const Container = styled.div`
  margin: 16px 10px 0;
`;

export const DetectCodeStatistics: React.FC = () => {
  const { loadingShops } = useShops();
  const { detectCodeStats, noMore, loadMore } = useDetectCodeStats();

  const isItemLoaded = useCallback(
    (index: number) => {
      return noMore || index < detectCodeStats.length;
    },
    [noMore, detectCodeStats]
  );

  if (loadingShops) {
    return <EmptyBox />;
  }

  const itemCount = noMore
    ? detectCodeStats.length
    : detectCodeStats.length + 1;

  return (
    <Box description="Detected codes">
      <Container>
        <ListHeader>
          <RowSection>Date</RowSection>
          <RowSection>Shop</RowSection>
          <RowSection>Code</RowSection>
          <RowSection>Success</RowSection>
        </ListHeader>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMore}
          threshold={0}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className={scrollStyle}
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
              ref={ref}
              height={350}
              width="100%"
              itemSize={50}
              itemData={detectCodeStats}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </Container>
    </Box>
  );
};
