import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from 'aws-exports.js';
import '@aws-amplify/ui-react/styles.css';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { amplifyTheme, muiTheme, authStyle } from 'app/theme';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

Amplify.configure(awsconfig);

const Wrapper: React.FC = ({ children }) => (
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <style>{authStyle}</style>
        <AmplifyProvider theme={amplifyTheme}>{children}</AmplifyProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

export default Wrapper;
