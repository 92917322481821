import React from 'react';
import styled from '@emotion/styled';

interface IProps {
  isExpired: boolean;
}

const Wrapper = styled.div`
  display: table-cell;
  width: 100%;
  text-align: right;
`;

const Content = styled.div<IProps>`
  display: inline-block;
  text-align: center;
  background-color: ${(props) => props.isExpired ? 'rgba(237, 94, 94, 0.5)' : 'rgba(95, 207, 148, 0.5)'};
  color: ${(props) => props.isExpired ? '#ff0000' : '#236f47'};
  border-radius: 6px;
  padding: 0 8px;
  margin-left: auto;
`;

export const Plan: React.FC<IProps> = ({ isExpired, children }) => (
  <Wrapper>
    <Content isExpired={isExpired}>{children}</Content>
  </Wrapper>
);
