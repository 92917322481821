import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  tooltipDescription: string;
}

const tooltipClass = css`
  width: 0.7em;
  height: 0.7em;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const StatCardTooltip: React.FC<IProps> = ({ tooltipDescription }) => (
  <TooltipWrapper>
    <Tooltip
      className={tooltipClass}
      title={tooltipDescription}
      placement="top-end"
      arrow
    >
      <InfoIcon />
    </Tooltip>
  </TooltipWrapper>
);
