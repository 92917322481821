import React from 'react';
import { convertToNumber, getPercent } from 'dashboard/utils';
import { COLORS } from 'common/consts';
import { BaseTiles } from './BaseTiles';
import {
  StatCardWrapper,
  StatCardData,
  StatCardTooltip,
  StatCardRate,
} from './StatCard';

export const CAATiles: React.FC = () => (
  <BaseTiles
    restPart={(stats) => (
      <>
        <StatCardWrapper backgroundColor={COLORS.yellow}>
          <StatCardTooltip tooltipDescription="The relation of shows slider to closes slider" />
          <StatCardData
            description="Show events"
            stat={convertToNumber(stats.showSlider)}
          />
          <StatCardData
            description="Close events"
            stat={convertToNumber(stats.closeSlider)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.closeSlider),
              convertToNumber(stats.showSlider)
            )}
          />
        </StatCardWrapper>
        <StatCardWrapper backgroundColor={COLORS.orange}>
          <StatCardTooltip tooltipDescription="The relation of starts to shows slider" />
          <StatCardData
            description="Start events"
            stat={convertToNumber(stats.start)}
          />
          <StatCardData
            description="Show events"
            stat={convertToNumber(stats.showSlider)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.start),
              convertToNumber(stats.showSlider)
            )}
          />
        </StatCardWrapper>
        <StatCardWrapper backgroundColor={COLORS.green}>
          <StatCardTooltip tooltipDescription="The relation of success to starts" />
          <StatCardData
            description="Success events"
            stat={convertToNumber(stats.success)}
          />
          <StatCardData
            description="Starts events"
            stat={convertToNumber(stats.start)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.success),
              convertToNumber(stats.start)
            )}
          />
        </StatCardWrapper>
        <StatCardWrapper backgroundColor={COLORS.red}>
          <StatCardTooltip tooltipDescription="The relation of abort to starts" />
          <StatCardData
            description="Abort events"
            stat={convertToNumber(stats.abort)}
          />
          <StatCardData
            description="Start events"
            stat={convertToNumber(stats.start)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.abort),
              convertToNumber(stats.start)
            )}
          />
        </StatCardWrapper>
      </>
    )}
  />
);
