import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { fetchDetectCodeStats, useStats } from 'dashboard/utils';

interface IUseDetectCodeStats {
  detectCodeStats: IDetectCodeStat[];
  loading: boolean;
  noMore: boolean;
  loadMore: () => void;
}

const START_PAGE = 1;
const PER_PAGE = 50;
const START_DATE = dayjs().unix();

const useDetectCodeStats = (): IUseDetectCodeStats => {
  const { currentShop } = useStats();
  const [detectCodeStats, setDetectCodeStats] = useState<IDetectCodeStat[]>([]);
  const [loading, setLoading] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [page, setPage] = useState(START_PAGE);

  const [firstInit, setFirstInit] = useState(true);

  const loadData = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    Auth.currentSession().then((session) =>
      fetchDetectCodeStats(session.getIdToken().getJwtToken(), {
        shopId: currentShop ? currentShop.id : undefined,
        startDate: START_DATE,
        page,
        perPage: PER_PAGE,
      })
        .then((res) => {
          setDetectCodeStats((prev) => (prev ? [...prev, ...res] : res));
          if (res.length < PER_PAGE) {
            setNoMore(true);
          }
          setPage((prev) => prev + 1);
          return;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false))
    );
  };

  useEffect(() => {
    if (firstInit) {
      setFirstInit(false);
      return;
    }

    setDetectCodeStats([]);
    setNoMore(false);
    setPage(START_PAGE);
  }, [currentShop]);

  return useMemo(
    () => ({
      detectCodeStats,
      loading,
      noMore,
      loadMore: loadData,
    }),
    [detectCodeStats, loading, noMore]
  );
};

export default useDetectCodeStats;
