import { apiUrl, castError } from 'common/utils';

type Params = {
  clientID?: string;
  shopId?: string;
  startDate?: any;
  endDate?: any;
};

function fetchGeneralStats(
  authToken: string,
  params?: Params
): Promise<IStats> {
  const query = {
    clientID: params?.clientID || 'demo',
    shop: params?.shopId,
    startDate: params?.startDate,
    endDate: params?.endDate,
  };
  return fetch(apiUrl('stats', query), {
    headers: {
      Authorization: authToken,
    },
  }).then(castError);
}

export default fetchGeneralStats;
