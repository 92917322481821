import React from 'react';
import styled from '@emotion/styled';
import { StatCardData } from './index'

interface IProps {
  rateStatistic: string;
}

const PercentSectionWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  text-align: center;
`;

export const StatCardRate: React.FC<IProps> = ({ rateStatistic }) => (
  <PercentSectionWrapper>
    <StatCardData
      size='small'
      description='Rate'
      stat={rateStatistic}
    />
  </PercentSectionWrapper>
);
