import { Theme } from '@aws-amplify/ui-react';
import { COLORS } from 'common/consts';

const amplifyTheme: Theme = {
  name: 'smartshopping',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: COLORS.white },
          20: { value: COLORS.primary },
          40: { value: COLORS.primary },
          60: { value: COLORS.primary },
          80: { value: COLORS.primary },
          90: { value: COLORS.primary },
          100: { value: COLORS.primary },
        },
      },
      font: {
        primary: { value: COLORS.black },
      },
    },
  },
};

export default amplifyTheme;
