import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  height: 340px;
  overflow: auto;
`;

const Table = styled.div`
  display: table;
  width: 100%;
  height: auto;
`;

export const TableWrapper: React.FC = ({ children }) => (
  <Container>
    <Table>{children}</Table>
  </Container>
);
