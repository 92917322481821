import React from 'react';
import { css } from '@emotion/css';
import Skeleton from '@mui/material/Skeleton';
import { COLORS } from 'common/consts';

interface IProps {
  fullWidth?: boolean;
}

export const EmptyBox: React.FC<IProps> = ({ fullWidth = false }) => {
  const boxClass = css`
    width: ${fullWidth ? '100%' : 'calc(50% - 30px)'};
    height: 500px;
    border: 1px solid ${COLORS.borderGray};
    border-radius: 6px;
    margin: 0 15px 40px;
  `;

  return (
    <Skeleton variant="rectangular" animation="wave" className={boxClass} />
  );
};
