import React from 'react';
import { convertToNumber, getPercent } from 'dashboard/utils';
import { COLORS } from 'common/consts';
import { BaseTiles } from './BaseTiles';
import {
  StatCardWrapper,
  StatCardData,
  StatCardTooltip,
  StatCardRate,
} from './StatCard';

export const StandardTiles: React.FC = () => (
  <BaseTiles
    countOfLoadingTiels={3}
    restPart={(stats) => (
      <>
        <StatCardWrapper backgroundColor={COLORS.green}>
          <StatCardTooltip tooltipDescription="The relation of inspects to shows slider" />
          <StatCardData
            description="Inspect events"
            stat={convertToNumber(stats.show)}
          />
          <StatCardData
            description="Show events"
            stat={convertToNumber(stats.showSlider)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.showSlider),
              convertToNumber(stats.show)
            )}
          />
        </StatCardWrapper>
        <StatCardWrapper backgroundColor={COLORS.yellow}>
          <StatCardTooltip tooltipDescription="The relation of shows slider to closes slider" />
          <StatCardData
            description="Show events"
            stat={convertToNumber(stats.showSlider)}
          />
          <StatCardData
            description="Close events"
            stat={convertToNumber(stats.closeSlider)}
            size="small"
          />
          <StatCardRate
            rateStatistic={getPercent(
              convertToNumber(stats.closeSlider),
              convertToNumber(stats.showSlider)
            )}
          />
        </StatCardWrapper>
      </>
    )}
  />
);
