import React, { useState, createContext, useMemo, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  fetchGeneralStats,
  getDates,
  noData,
} from 'dashboard/utils';

export interface IStatsContext {
  dateRange: IDateRange;
  loadingStats: boolean;
  stats: IStats | null;
  currentShop: IMerchant | null;
  tableRows: ITableRow[] | null;
  changeDateRange: (type: TDateRange) => void;
  onChoiceStore: (value: IMerchant) => void;
  onResetStore: () => void;
}

const StatsContext = createContext({} as IStatsContext);

const StatsProvider: React.FC = ({ children }) => {
  const [dateRange, setDateRange] = useState<IDateRange>(getDates('lastWeek'));
  const [currentShop, setCurrentShop] = useState<IMerchant | null>(null);
  const [stats, setStats] = useState<IStats | null>(null);
  const [loadingStats, setLoadingStats] = useState(true);

  useEffect(() => {
    setLoadingStats(true);
    Auth.currentSession()
      .then((session) =>
        fetchGeneralStats(session.getIdToken().getJwtToken(), {
          shopId: currentShop ? currentShop.id : undefined,
          startDate: dateRange.start,
          endDate: dateRange.end,
        })
      )
      .then(setStats)
      .catch((error) => console.log(error))
      .finally(() => setLoadingStats(false));
  }, [dateRange]);

  const changeDateRange = (type: TDateRange) => {
    setDateRange(getDates(type));
  };

  const tableRows: ITableRow[] = useMemo(
    () =>
      dateRange.dates
        .map((rangeDate) => {
          const [year, month, day] = rangeDate.split('-');
          return {
            date: rangeDate,
            stats: stats?.[year]?.[month]?.[day] ?? noData,
          };
        })
        .reverse(),
    [dateRange, stats]
  );

  const resetCurrentShop = () => {
    setCurrentShop(null);
  };

  const onChoiceStore = (value: IMerchant) => {
    setLoadingStats(true);
    Auth.currentSession()
      .then((session) =>
        fetchGeneralStats(session.getIdToken().getJwtToken(), {
          shopId: value.id,
          startDate: dateRange.start,
          endDate: dateRange.end,
        })
      )
      .then((res) => {
        setStats(res);
        setCurrentShop(value);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoadingStats(false));
  };

  const onResetStore = () => {
    resetCurrentShop();
    setLoadingStats(true);
    Auth.currentSession()
      .then((session) =>
        fetchGeneralStats(session.getIdToken().getJwtToken(), {
          startDate: dateRange.start,
          endDate: dateRange.end,
        })
      )
      .then(setStats)
      .catch((error) => console.log(error))
      .finally(() => setLoadingStats(false));
  };

  const value: IStatsContext = useMemo(
    () => ({
      dateRange,
      loadingStats,
      stats,
      currentShop,
      tableRows,
      changeDateRange,
      onChoiceStore,
      onResetStore,
    }),
    [
      dateRange,
      loadingStats,
      stats,
      currentShop,
      tableRows,
      changeDateRange,
      onChoiceStore,
      onResetStore,
    ]
  );

  return (
    <StatsContext.Provider value={value}>{children}</StatsContext.Provider>
  );
};

export { StatsProvider };

export default StatsContext;
