import React from 'react';
import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';

const className = css`
  font-size: 18px;
`;

export const Subtitle: React.FC = ({ children }) => (
  <Typography className={className}>{children}</Typography>
);
