import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Caption = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

const EmptyList: React.FC = () => (
  <Wrapper>
    <Caption>No matches</Caption>
  </Wrapper>
);

export default EmptyList;
