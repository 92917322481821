import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Tooltip, IconButton } from '@mui/material';
import { COLORS } from 'common/consts';

const Wrapper = styled.div`
  margin: 40px 0;
`;

const Title = styled.h1`
  display: flex;
  font-weight: 800;
  margin-bottom: 12px;
`;

const Subtitle = styled.span`
  font-weight: 500;
`;

const iconButtonClass = css`
  width: 25px;
  height: 25px;
  margin-top: -5px;
  color: ${COLORS.primary};
`;

export const Greeting: React.FC = () => (
  <Wrapper>
    <Title>
      Dashboard
      <Tooltip
        placement="right"
        title="The dashboard is in beta testing now"
      >
        <IconButton classes={{ root: iconButtonClass }} size="small">β</IconButton>
      </Tooltip>
    </Title>
    <Subtitle>Welcome back, nice to see you again!</Subtitle>
  </Wrapper>
);
