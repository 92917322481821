import React from 'react';
import times from 'lodash/times';
import { useStats, convertToNumber, getPercent } from 'dashboard/utils';
import { TilesWrapper } from './TilesWrapper';
import { LoadingCard } from './StatCard/LoadingCard';
import {
  StatCardWrapper,
  StatCardData,
  StatCardTooltip,
  StatCardRate,
} from './StatCard';

interface IProps {
  countOfLoadingTiels?: number;
  restPart: (stats: IStatsUnit) => JSX.Element;
}

export const BaseTiles: React.FC<IProps> = ({ countOfLoadingTiels = 5, restPart }) => {
  const { stats, loadingStats } = useStats();

  if (!stats || loadingStats) {
    return (
      <TilesWrapper>
        {times(countOfLoadingTiels, (index) => (
          <LoadingCard key={index} />
        ))}
      </TilesWrapper>
    );
  }

  const { checkout, show } = stats.total;

  return (
    <TilesWrapper>
      <StatCardWrapper contentColor="white">
        <StatCardData
          description="Checkout events"
          stat={convertToNumber(checkout)}
        />
        <StatCardTooltip tooltipDescription="The relation of checkouts to inspects" />
        <StatCardData
          description="Inspect events"
          stat={convertToNumber(show)}
          size="small"
        />
        <StatCardRate
          rateStatistic={getPercent(
            convertToNumber(show),
            convertToNumber(checkout)
          )}
        />
      </StatCardWrapper>
      {restPart(stats.total)}
    </TilesWrapper>
  );
};
