import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import { UserContext } from 'common/contexts/UserContext';
import { COLORS } from 'common/consts';
import logoPath from './img/logo.png';

interface IIconProps {
  src: string;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 60px;
  background-color: ${COLORS.white};
`;

const Icon = styled.img<IIconProps>`
  src: ${(props) => props.src};
  height: 100%;
`;

const rootButtonClass = css`
  width: 125px;
  height: 55px;
  font-size: 1rem;
`;

export const Header: React.FC = () => {
  const { signOut } = useContext(UserContext);

  return (
    <HeaderWrapper>
      <Icon src={logoPath} />
      <Button
        classes={{ root: rootButtonClass }}
        variant="contained"
        onClick={signOut}
      >
        Log out
      </Button>
    </HeaderWrapper>
  );
};
