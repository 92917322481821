import { getCoordsFromData } from 'dashboard/utils';
import getWeekData from './getWeekData';

const getDetectChartData = (stats: IStats | null, dateRange: IDateRange) => {
  if (!stats) {
    return null;
  }

  const weekData = getWeekData(stats, dateRange);

  return [
    {
      id: 'Close',
      data: getCoordsFromData(weekData, 'closeSlider'),
    },
    {
      id: 'Show',
      data: getCoordsFromData(weekData, 'showSlider'),
    },
    {
      id: 'Detect',
      data: getCoordsFromData(weekData, 'detect'),
    },
    {
      id: 'Inspect',
      data: getCoordsFromData(weekData, 'show'),
    },
    {
      id: 'Checkout',
      data: getCoordsFromData(weekData, 'checkout'),
    },
  ];
};

export default getDetectChartData;
