import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useStats } from 'dashboard/utils';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine, Serie, Line } from '@nivo/line';
import { COLORS } from 'common/consts';
import { EmptyBox, Box } from 'dashboard/components/Box';

interface IProps {
  title: string;
  chartData: Serie[] | null;
  loading: boolean;
  fullWidth?: boolean;
}

interface IChartWrapperProps {
  dateRangeType: TDateRange;
}

const ChartWrapper = styled.div<IChartWrapperProps>`
  width: 100%;
  height: 400px;
  overflow: ${(props) =>
    props.dateRangeType === 'lastWeek' ? 'initial' : 'auto'};
`;

export const Chart: React.FC<IProps> = ({ title, chartData, loading, fullWidth }) => {
  const { dateRange } = useStats();
  const chartWidth = dateRange.type === 'last30Days' ? 1000 : 3000;

  if (loading || !chartData) {
    return <EmptyBox fullWidth={fullWidth} />;
  }

  if (dateRange.type === 'lastWeek') {
    return (
      <Box description={title} fullWidth={fullWidth}>
        <ChartWrapper dateRangeType={dateRange.type}>
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 20, bottom: 55, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 0,
              legendPosition: 'middle',
            }}
            enableGridX={false}
            colors={[
              COLORS.red,
              COLORS.blue,
              COLORS.green,
              COLORS.orange,
              COLORS.yellow,
              COLORS.black,
              COLORS.primary,
            ]}
            enablePoints={false}
            pointSize={4}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableArea={true}
            areaBaselineValue={10}
            areaOpacity={0.1}
            useMesh={true}
            defs={[
              linearGradientDef('gradientA', [
                { offset: 0, color: 'inherit' },
                { offset: 100, color: 'inherit', opacity: 0 },
              ]),
            ]}
            fill={[{ match: '*', id: 'gradientA' }]}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'row',
                justify: false,
                translateX: 30,
                translateY: 55,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 10,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
              },
            ]}
          />
        </ChartWrapper>
      </Box>
    );
  }

  return (
    <Box description={title} fullWidth={fullWidth}>
      <ChartWrapper dateRangeType={dateRange.type}>
        <Line
          data={chartData}
          width={chartWidth}
          height={375}
          margin={{ top: 50, right: 20, bottom: 55, left: 50 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="linear"
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 0,
            legendPosition: 'middle',
          }}
          enableGridX={false}
          colors={[
            COLORS.red,
            COLORS.blue,
            COLORS.green,
            COLORS.orange,
            COLORS.yellow,
            COLORS.black,
            COLORS.primary,
          ]}
          enablePoints={false}
          pointSize={4}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          enableArea={true}
          areaBaselineValue={10}
          areaOpacity={0.1}
          useMesh={true}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          legends={[
            {
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 55,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 10,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
            },
          ]}
        />
      </ChartWrapper>
    </Box>
  );
};
