import React from 'react';
import styled from '@emotion/styled';
import SwipeableViews from 'react-swipeable-views';
import { useUserPlan, useStats } from 'dashboard/utils';
import {
  getStandardChartData,
  getCAAChartData,
  getDetectChartData,
} from './utils';
import { Chart } from 'dashboard/components/Statistics/components/MainInfo/components';
import {
  StandardTiles,
  CAATiles,
  DetectTiles,
  StandardTable,
  CAATable,
  DetectTable,
} from './components';
import { DetectCodeStatistics } from 'dashboard/components/Statistics/components/MainInfo/components';

interface IProps {
  currentTab: number;
  onTabChange: (value: number) => void;
}

const BoxsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -15px 0;
`;

const TabWrapper = styled.div`
  overflow-x: hidden;
`;

export const Infographics: React.FC<IProps> = ({ currentTab, onTabChange }) => {
  const { userPlan } = useUserPlan();
  const { stats, dateRange, loadingStats } = useStats();

  if (userPlan === 'Standard+') {
    return (
      <>
        <StandardTiles />
        <BoxsWrapper>
          <Chart
            title="Main data chart"
            chartData={getStandardChartData(stats, dateRange)}
            loading={loadingStats}
          />
          <StandardTable />
        </BoxsWrapper>
      </>
    );
  } else if (userPlan === 'Essential') {
    return (
      <>
        <DetectTiles />
        <BoxsWrapper>
          <Chart
            title="Detect chart"
            chartData={getDetectChartData(stats, dateRange)}
            loading={loadingStats}
          />
          <DetectTable />
          <DetectCodeStatistics />
        </BoxsWrapper>
      </>
    );
  } else if (userPlan === 'Plus') {
    return (
      <>
        <CAATiles />
        <BoxsWrapper>
          <Chart
            title="CAA chart"
            chartData={getCAAChartData(stats, dateRange)}
            loading={loadingStats}
          />
          <CAATable />
        </BoxsWrapper>
      </>
    );
  } else {
    return (
      <SwipeableViews
        index={currentTab}
        onChangeIndex={onTabChange}
        containerStyle={{
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
        }}
      >
        <TabWrapper role="tabpanel" hidden={currentTab !== 0}>
          <CAATiles />
          <BoxsWrapper>
            <Chart
              title="CAA chart"
              chartData={getCAAChartData(stats, dateRange)}
              loading={loadingStats}
            />
            <CAATable />
          </BoxsWrapper>
        </TabWrapper>
        <TabWrapper role="tabpanel" hidden={currentTab !== 1}>
          <DetectTiles />
          <BoxsWrapper>
          <DetectCodeStatistics />
          <DetectTable />
            <Chart
              title="Detect chart"
              chartData={getDetectChartData(stats, dateRange)}
              loading={loadingStats}
              fullWidth
            />
          </BoxsWrapper>
        </TabWrapper>
      </SwipeableViews>
    );
  }
};
