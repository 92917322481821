import { noData } from 'dashboard/utils';

const getWeekData = (stats: IStats, dateRange: IDateRange) =>
  dateRange.dates.map((rangeDay) => {
    const [year, month, day] = rangeDay.split('-');
    const statsUnit: IStatsUnit = stats?.[year]?.[month]?.[day] ?? noData;
    const dayData = { date: rangeDay, stats: statsUnit };
    return dayData;
  });

export default getWeekData;
