import React from 'react';
import styled from '@emotion/styled';
import { Container } from 'common/components';
import { UserPlanProvider } from './contexts/UserPlanContext';
import { COLORS } from 'common/consts';
import { Header, Greeting, UserInfo, Statistics } from './components';

const Wrapper = styled.div`
  background-color: ${COLORS.extraLightGray};
`;

export const Dashboard: React.FC = () => (
  <Wrapper>
    <UserPlanProvider>
      <Header />
      <Container>
        <Greeting />
        <UserInfo />
        <Statistics />
      </Container>
    </UserPlanProvider>
  </Wrapper>
);
