const noData: IStatsUnit = {
  checkout: '0',
  showSlider: '0',
  closeSlider: '0',
  show: '0',
  detect: '0',
  start: '0',
  success: '0',
  abort: '0',
  avgTotal: '0',
  avgDiscountTotal: '0',
  avgDiscount: '0',
};

export { noData };
